function docReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

let scrollPosition = 0;
let navbarElement = document.getElementById('topNavbar');
let disableNavbarExpand = false;



let activeIf = {
    "navbar-spacer":"navbar-spacer",
    "intro-section": "activeIfIntro",
    "photo-slider-section": "activeIfPhoto",
    "video-slider-wrapper": "activeIfVideo",
    "contact-section": "activeIfContact",
}

docReady(() => {

    let customCollapseButtons = document.getElementsByClassName("customCollapseButton");

    for (let customCollapseButton of customCollapseButtons) {
        customCollapseButton.addEventListener('click', (e) => {
            let target = customCollapseButton.getAttribute('data-cc-target-id');
            console.log(window.innerWidth);
            if (window.innerWidth < 1200) {
                toggleTarget(target);
            }
        });
    }

    window.addEventListener("scroll", () => {
        scrollPosition = window.pageYOffset;
        //console.log("disableNavbarExpand", disableNavbarExpand);


        let activeItems = Object.keys(activeIf)
        let activeTarget = null;

        activeItems.forEach((id) => {
            const targetElement = document.getElementById(activeIf[id]);

            if (targetElement == null) {
                return 
            } 

            if (isInViewportByTop(document.getElementById(id)) && activeTarget == null) {
                activeTarget = targetElement;
            }
        });

        if (activeTarget != null) {
            activeItems.forEach((id) => {
                const targetElement = document.getElementById(activeIf[id]);

                if (targetElement !== null) {
                    targetElement.classList.remove('active');
                } else {
                    return
                }
            });
            activeTarget.classList.add('active');
        }


        if (disableNavbarExpand) {
            return;
        }

        if (scrollPosition >= 1) {
            navbarElement.classList.remove('expanded');
        } else {
            navbarElement.classList.add('expanded');
        }
    });

});


function isInViewportByTop(element) {
    const rect = element.getBoundingClientRect();

    return (
        rect.top <= 100 &&
        rect.top >= -100
    );
}



/**
 * 
 * @param {string} id 
 */
function toggleTarget(id) {
    let toggleElement = document.getElementById(id);

    //Disable any if the progress is not done!
    if (toggleElement.classList.contains('collapsing')) {
        return
    }

    toggleElement.classList.add('collapsing')
    toggleElement.classList.remove('collapse')

    let isOpen = toggleElement.classList.contains('show');

    if (isOpen) {

        toggleElement.classList.remove('show');
        if (scrollPosition <= 1) {
            //navbarElement.classList.add('expanded');
        }
        disableNavbarExpand = false;
    } else {
        toggleElement.classList.add('show');
        navbarElement.classList.remove('expanded');
        disableNavbarExpand = true;
    }



    setTimeout(() => {
        toggleElement.classList.remove('collapsing')
        toggleElement.classList.add('collapse')

    }, isOpen ? 700 : 600)

}