<template>
  <div>
    <div class="row main-row">
      <button
        v-show="!previewItem"
        class="carousel-control-prev"
        type="button"
        :data-bs-target="'#carouselExampleControls' + uid"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <div class="preview-wrapper" v-if="previewItem">
        <div class="preview-close" v-on:click="previewClose">x</div>
        <div
          class="media-preview"
          v-if="previewItem"
          :style="{
            width: 'auto',
            zIndex: 5,
            position: 'absolute',
            top: previewPosition.top + 'px',
            bottom: previewPosition.bottom + 'px',
            left: previewPosition.left + 'px',
            right: previewPosition.right + 'px',
          }"
        >
          <figure
            v-if="previewItem && previewItem.url && previewItem.type == 'image'"
            class="image"
          >
          <!--:src="previewItem.show_url"-->
            <img v-lazy="previewItem.show_url"  class="img-fluid" />
          </figure>

          <video
            v-if="
              previewItem &&
              previewItem.url &&
              previewItem.type == 'video' &&
              item.youtubeEmbed == null
            "
            autoplay
            loop
            muted
            class="img-fluid video-image video"
          >
            <source
              :src="previewItem.url"
              :type="previewItem.mimeType"
              class="img-fluid"
            />

            Your browser can't play this video!
          </video>

          <p v-if="!animationRun">{{ previewItem.name }}</p>
        </div>
      </div>
      <div
        :id="'carouselExampleControls' + uid"
        class="carousel slide photo-slider-container"
        data-bs-ride="carousel"
        ref="carouselRef"
        data-bs-pause="hover"
        data-bs-interval="false"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            :class="{ active: sliderIndex == 0 }"
            v-for="(slider, sliderIndex) in sliders"
            v-bind:key="'slider-index' + sliderIndex"
          >
            <div class="">
              <div
                class="image-wrapper row"
                :class="{
                  //opened: previewItem !== null && previewItem !== undefined,
                }"
              >
                <div
                  v-for="(chunk, colIndex) in slider"
                  v-bind:key="'col-index-' + colIndex"
                  :class="{
                    col: chunkedSelectedImages.length < 2,
                    'col-6': chunkedSelectedImages.length == 2,
                    'col-4': chunkedSelectedImages.length == 3,
                  }"
                >
                  <div class="row media-row">
                    <div
                      class="col-12 media-wrapper"
                      v-for="(item, mediaIndex) in chunk"
                      v-bind:key="
                        'slider-index-' +
                        sliderIndex +
                        '-col-index-' +
                        colIndex +
                        '-media-index-' +
                        mediaIndex
                      "
                      :ref="
                        (el) => {
                          imageRef[
                            'slider-index-' +
                              sliderIndex +
                              '-col-index-' +
                              colIndex +
                              '-media-index-' +
                              mediaIndex
                          ] = el;
                        }
                      "
                    >
                      <div
                        class="hover-overlay"
                        v-if="item && item.url && item.type == 'image'"
                      >
                        <p class="name">{{ item.name }}</p>
                      </div>
                      <figure
                        v-if="item && item.url && item.type == 'image'"
                        class="image"
                        v-on:click="
                          preview(
                            'slider-index-' +
                              sliderIndex +
                              '-col-index-' +
                              colIndex +
                              '-media-index-' +
                              mediaIndex,
                            item
                          )
                        "
                        :class="{
                          preview:
                            'slider-index-' +
                              sliderIndex +
                              '-col-index-' +
                              colIndex +
                              '-media-index-' +
                              mediaIndex ==
                            previewIndex,
                        }"
                      >
                        <!--:src="item.preview_url"-->
                        <img  v-lazy="item.preview_url"  class="img-fluid" />
                      </figure>

                      <video
                        v-if="
                          item &&
                          item.url &&
                          item.type == 'video' &&
                          item.youtubeEmbed == null
                        "
                        autoplay
                        loop
                        muted
                        class="img-fluid video-image video"
                        v-on:click="
                          preview(
                            'slider-index-' +
                              sliderIndex +
                              '-col-index-' +
                              colIndex +
                              '-media-index-' +
                              mediaIndex,
                            item
                          )
                        "
                        :class="{
                          preview:
                            'slider-index-' +
                              sliderIndex +
                              '-col-index-' +
                              colIndex +
                              '-media-index-' +
                              mediaIndex ==
                            previewIndex,
                        }"
                      >
                        <source
                          :src="item.url"
                          :type="item.mimeType"
                          class="img-fluid"
                        />

                        Your browser can't play this video!
                      </video>

                          <!--:src="
                          item.youtubeEmbed +
                          '?autoplay=1&controls=0&mute=1&'+loopMaking(item.youtubeEmbed)
                        "-->
                      <iframe
                        :ref="
                          (el) => {
                            iframeRef[item.id] = el;
                          }
                        "
                        class="youtube-embed"
                        v-if="item && item.youtubeEmbed && item.type == 'video'"
                        
                        v-lazy="item.youtubeEmbed +
                          '?autoplay=1&controls=0&mute=1&'+loopMaking(item.youtubeEmbed)"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay; controls; loop; mute"
                        :height="
                          itemHeights[item.id] ? itemHeights[item.id] : 250
                        "
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-next"
        type="button"
        :data-bs-target="'#carouselExampleControls' + uid"
        data-bs-slide="next"
        v-show="!previewItem"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onBeforeUpdate } from "vue";
import axios from "axios";
import * as bootstrap from "bootstrap";

export default defineComponent({
  name: "slide-manager",
  props: {
    type: String,
  },
  components: {
    
  },
  data() {
    return {
      itemHeights: {},
      uid: 0,
      previewIndex: null,
      previewItem: null,
      modalOpen: false,
      sliders: {},
      animationRun: false,
      previewPosition: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: 0,
        height: 0,
      },
    };
  },
  setup() {
    const iframeRef = ref({});
    const carouselRef = ref(null);
    const imageRef = ref({});

    return {
      iframeRef,
      carouselRef,
      imageRef,
    };
  },
  mounted() {
    this.uid = Math.floor(Math.random(0, 10) * 200);
    this.getAllSliders().then(() => {
      this.calcHeights();
    });

    this.carouselRef.addEventListener("slide.bs.carousel", () => {
      this.calcHeights();
    });
  },
  methods: {
    calcHeights() {
      setTimeout(() => {
        Object.getOwnPropertyNames(this.iframeRef).map((itemId) => {
          //console.log("itemId", itemId);
          const el = this.iframeRef[itemId];
          let outputHeight = 250;
          if (el) {
            let height = (9 / 16) * el.offsetWidth;
            //console.log(el.offsetWidth, height);
            outputHeight = height < 250 ? 200 : height;
          }
          this.itemHeights[itemId] = outputHeight;
        });
      }, 500);
    },
    getAllSliders() {
      let params = {};
      if (this.type) {
        params = new URLSearchParams([["type", this.type]]);
      }
      return axios
        .get(`/public/slider-api/all-slider`, { params })
        .then((res) => {
          this.sliders = res.data.sliders.map((slide) => {
            return this.chunkedSelectedImages(slide);
          });
        })
        .catch((error) => {
          console.error("O'ops", error);
        });
    },
    chunkedSelectedImages(slider) {
      let output = [];

      let selectedArrayMax = 3;
      if (slider.images.length < 5) {
        selectedArrayMax = 2;
      }

      for (let i = 0; i < selectedArrayMax; i++) {
        output[i] = [];
      }

      if (slider.images !== undefined) {
        let selectedArray = 0;

        slider.images.forEach((image, index) => {
          output[selectedArray].push(image);
          selectedArray++;
          if (selectedArray >= selectedArrayMax) {
            selectedArray = 0;
          }
        });
      }
      output = output.filter((item) => {
        return item.length > 0;
      });
      return output;
    },

    async preview(index, item) {
      if (item.type !== "image") {
        return;
      }
      this.previewIndex = index;
      this.previewItem = item;

      if (this.imageRef[index] !== undefined) {
        /**
         * @type {Element}
         */
        const mediaElement = this.imageRef[index];

        const offsetBottom =
          mediaElement.offsetParent.offsetHeight -
          (mediaElement.offsetTop + mediaElement.offsetHeight);

        const offsetRight =
          mediaElement.offsetParent.offsetWidth -
          (mediaElement.offsetLeft + mediaElement.offsetWidth);

        //Setting up offset positions
        this.previewPosition.top = mediaElement.offsetTop;
        this.previewPosition.bottom = offsetBottom;
        this.previewPosition.left = mediaElement.offsetLeft;
        this.previewPosition.right = offsetRight;

        this.previewPosition.width = mediaElement.offsetWidth;
        this.previewPosition.height = mediaElement.offsetHeight;

        this.animationRun = true;
        await this.scaleUpAnimation(1);
        this.animationRun = false;
      }
    },
    /**
     *@returns {Promise}
     */
    async scaleUpAnimation(speed, stepTop, stepBottom, stepLeft, stepRight) {
      stepTop = stepTop
        ? stepTop
        : this.calcStepDistance(this.previewPosition.top, speed);
      stepBottom = stepBottom
        ? stepBottom
        : this.calcStepDistance(this.previewPosition.bottom, speed);
      stepLeft = stepLeft
        ? stepLeft
        : this.calcStepDistance(this.previewPosition.left, speed);
      stepRight = stepRight
        ? stepRight
        : this.calcStepDistance(this.previewPosition.right, speed);

      let hasChange = false;

      if (this.previewPosition.top > 0) {
        hasChange = true;
        this.previewPosition.top = this.calcStep(
          this.previewPosition.top,
          stepTop
        );
      }
      if (this.previewPosition.bottom > 0) {
        hasChange = true;
        this.previewPosition.bottom = this.calcStep(
          this.previewPosition.bottom,
          stepBottom
        );
      }
      if (this.previewPosition.left > 0) {
        hasChange = true;
        this.previewPosition.left = this.calcStep(
          this.previewPosition.left,
          stepLeft
        );
      }
      if (this.previewPosition.right > 0) {
        hasChange = true;
        this.previewPosition.right = this.calcStep(
          this.previewPosition.right,
          stepRight
        );
      }

      if (hasChange) {
        return new Promise((resolve) => {
          setTimeout(() => {
            this.scaleUpAnimation(
              speed,
              stepTop,
              stepBottom,
              stepLeft,
              stepRight
            ).then(() => {
              resolve();
            });
          }, 1);
        });
      }

      return Promise.resolve();
    },
    /**
     * calculate the step size
     */
    calcStepDistance(distance, speed) {
      return (distance / 100) * speed;
    },

    calcStep(actual, step) {
      const targetPos = actual - step;
      return targetPos <= 0 ? 0 : targetPos;
    },

    previewClose() {
      this.previewIndex = undefined;
      this.previewItem = undefined;
    },
    /**
     * this method help form making loop. 
     * like this: https://www.youtube.com/embed/VIDEO_ID?playlist=VIDEO_ID&loop=1
     * return with: playlist=VIDEO_ID&loop=1
     * @param {string} inputYoutubeVideoLink
     */
    loopMaking(inputYoutubeVideoLink){
      if(inputYoutubeVideoLink == null){
        return "";

      }
      const embedForReplace ="https://www.youtube.com/embed/";
      const  videoId = inputYoutubeVideoLink.replace(embedForReplace, "")

      return "playlist="+ videoId + "&loop=1";
    }
  },
});
</script>
